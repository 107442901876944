@import url("https://fonts.googleapis.com/css2?family=Kavoon&display=swap");

.container {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../images/port1.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 0;
  height: 100vh;
  width: 100vw;
  min-width: 300px;
  min-height: 500px;
}

.inner-div {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.inner-div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: url("../../images/sardegna-1.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.35;
  z-index: -1;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3px;
  /* min-width: 280px; */
  width: 250px;
  height: auto;
  background-color: #0e54bb;
  border: 3px solid #0e54bb;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  z-index: 1;
}

.logo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top-left-radius: 10px;
  justify-content: center;
  margin-bottom: 3px;
  width: 100%;
  height: auto;
  gap: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #0e54bb;
}

.logo {
  width: 40px;
  height: 40px;
  background-image: url("../../images/logo_white.png");
  /* content: ""; */
  top: 0;
  left: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  /* z-index: 100; */
}

.titolo {
  font-family: "Kavoon", sans-serif;
  color: #ffffff;
  font-size: 18px;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
}

.form-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #4081e1;
  width: 100%;
  height: auto;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;

  border: 2px solid rgb(255, 255, 255);
  /* min-width: 250px; */
}

.form-container input::placeholder {
  font-family: Arial;
  font-size: 14px;
}

.form-container input {
  width: 85%;
  padding: 5px;
  border: 1px solid #ccc;

  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
}

.form-container button {
  width: auto;
  padding: 5px 20px;
  background-color: #0e54bb;
  color: white;
  border: none;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  cursor: pointer;
  margin-top: 0px;
}

.form-container button:hover {
  font-weight: bold;
  background-color: white;
  color: #0e54bb;
}

.radio-button {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.radio-button p {
  white-space: nowrap;
  color: #ffffff;
  align-items: center;
  font-family: Arial;
  font-size: 14px;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
}

.custom-checkbox {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.custom-checkbox .checkmark {
  position: relative;
  display: inline-block;
  height: 10px;
  width: 10px;
  background-color: white; /* Sfondo bianco */
  border: 2px solid #ccc; /* Bordo grigio */
  border-radius: 3px; /* Angoli arrotondati */
  transition: background-color 0.3s, border-color 0.3s; /* Transizione */
  margin-right: 10px; /* Spazio tra checkbox e testo */
}

.custom-checkbox input:checked + .checkmark {
  border-color: #0e54bb; /* Cambia colore del bordo */
}

.custom-checkbox input:checked + .checkmark:after {
  content: "";
  position: absolute;
  left: 6px;
  top: 2px;
  width: 6px;
  height: 12px;
  border: solid #0e54bb; /* Colore della spunta */
  border-width: 0 2px 2px 0;
  transform: rotate(45deg); /* Crea una spunta */
}

.radio-button-accept {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.radio-button-accept p {
  white-space: nowrap;
  color: #ffffff;
  align-items: center;
  font-family: Arial;
  font-size: 12px;
}

.links-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* min-width: 250px; */
  margin-top: 3px;
  flex-direction: column;
  align-items: center;
  font-family: Arial;
  gap: 5px;
  padding-top: 7px;
  padding-bottom: 7px;
  background-color: #0e54bb;
  /* border-bottom-left-radius: 10px; */
  border-bottom-right-radius: 10px;
}

.form-container a {
  color: #ffffff;
  text-decoration: none;
  font-size: clamp(12px, 1.5vw, 14px);
}

.form-container a:hover {
  font-weight: bold;
}

.links-container a {
  color: #ffffff;
  text-decoration: none;
  font-size: clamp(12px, 1.5vw, 14px);
}

.links-container a:hover {
  font-weight: bold;
  cursor: pointer;
}

.password-wrapper {
  position: relative;
  width: 100%; /* Stesso width degli altri input */
  display: flex;
  align-items: center;

  justify-content: center;
}

.password-wrapper input {
  width: 85%;
  /* padding-right: 30px; */
}

.eye-icon {
  position: absolute;
  right: 15px;
  cursor: pointer;
  font-size: 1.2em; /* Dimensione dell'icona */
  color: #0e54bb; /* Colore dell'icona */
}
