.cookie-banner-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #0e54bb;
  color: #fff;
  padding: 20px;
  text-align: center;
  z-index: 1000;
}

.cookie-banner-text {
  margin: 0;
  font-size: 14px;
}

.cookie-banner-link {
  color: #fff;
  text-decoration: underline;
}

.cookie-banner-button-container {
  margin-top: 10px;
}

.cookie-banner-button {
  color: #0e54bb;
  background-color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
}

.cookie-banner-button-decline {
  color: #fff;
  background-color: #444;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}
