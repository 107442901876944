@import url("https://fonts.googleapis.com/css2?family=Kavoon&display=swap");

.container-home {
  margin-top: 60px;
  height: calc(100vh - 60px);
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../images/port.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 0;
}

.page-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  font-family: Arial, sans-serif;
}

.dati-container {
  display: flex;
  justify-content: top;
  flex-direction: column;
  height: 100%;
}

.dati-container-seleziona {
  display: flex;
  justify-content: center;

  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #3498db;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  color: white;
  font-size: 2.5vw;
  font-weight: bold;
}

.top1 {
  background-color: #3498db;
  color: white;
  text-align: center;
  /* padding: 5px; */
  height: auto;
  width: auto;
  margin-top: 0px;
}

.top1-dati {
  font-family: Arial;
  /* padding: 5px; */
  /* padding-left: 20px; */
  font-size: 16px;
  font-weight: bold;
  color: white;
  width: auto;
  height: 100%;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  /* display: inline-block; */
  margin-top: 0px;
}

.top2 {
  background-color: #f700ff;
  color: white;
  text-align: center;
  padding: 5px;
  height: auto;
  width: auto;
}

.inner-page {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 96%;
  width: 100%;
  z-index: 1;
  /* border: 2px solid red; */
  padding: 5px;
  gap: 5px;
}

.inner-dx {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 5px solid #0056b3;
}

.inner-dx::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  opacity: 0.65;
  z-index: -1;
}

.inner-sx {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 1;

  /* border: 2px solid red; */
}

/* .inner-sx::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  opacity: 0.65;
  z-index: -1;
} */

.Menu {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Spazio tra gli elementi */
  position: fixed;
  top: 0; /* Menu in cima alla pagina */
  left: 0;
  width: 100%; /* Larghezza completa */
  height: 60px; /* Altezza del menu */
  background-color: #0056b3;
  padding: 0 20px; /* Spazi interni laterali */
  z-index: 1000;
  box-sizing: border-box;
}

.previous-page {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Allinea a sinistra */
  cursor: pointer;
}

/* Contenitore per i pulsanti "Show" e "Create" */
.menu-buttons {
  display: flex;
  gap: 2vh; /* Spazio tra i pulsanti "Show" e "Create" */
}

/* Stile dei pulsanti generici */
.Menu button {
  width: 50px;
  height: 50px;
  /* padding: 5px 10px; */
  background-color: #0056b3;
  color: white;
  border: none;
  border-radius: 5px;
  /* cursor: pointer; */
}

/* Cambia colore al passaggio del mouse */
.Menu button:hover {
  background-color: #0056b3;
}

.Menu button.active {
  background-color: #0056b3;
  color: yellow; /* Cambia il colore del testo quando il pulsante è attivo */
}

/* Allineamento del pulsante "LogOut" a destra */
.logout {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Allinea a destra */
  margin-left: auto;
  margin-right: 10px;
}
.logout button {
  cursor: pointer;
}

.menu button img {
  width: 5vw; /* L'immagine si adatta alla larghezza del bottone */
  height: 5vh; /* L'immagine si adatta all'altezza del bottone */
  object-fit: contain; /* Mantieni le proporzioni dell'immagine */
}

.icon-create {
  color: rgb(255, 255, 255);
  font-size: 24px; /* Definisci una dimensione specifica */
}

.logo-container-menu {
  display: flex;
  flex-direction: row;
  align-items: center; /* Allinea verticalmente */
  justify-content: center; /* Allinea orizzontalmente */
  flex: 1; /* Occupa spazio centrale */
  text-align: center;
  gap: 5px;
}

.logo-menu {
  width: 40px;
  height: 40px;
  background-image: url("../../images/logo_white.png");
  /* content: ""; */
  top: 0;
  left: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  /* z-index: 100; */
}

.h1 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Kavoon", sans-serif;
  color: #ff0000;
  font-size: 24px;
}

.titolo-menu {
  font-family: "Kavoon", sans-serif;
  color: #ffffff;
  font-size: 18px;
}
/* Media Queries per orientamento */

/* Smartphone in modalità verticale */
@media (max-width: 768px) and (orientation: portrait) {
  .container-home {
    margin-top: 6vh;

    height: 100vh;
    background-size: cover;
  }

  .inner-sx {
    height: auto;
  }

  .page-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    font-family: Arial, sans-serif;
  }

  .inner-page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    height: 96%;
    width: 100%;
    z-index: 1;
    /* border: 2px solid red; */
    padding: 5px;
    gap: 5px;
  }

  .top1 {
    background-color: #3498db;
    color: white;
    text-align: center;
    padding: 5px;
    height: auto;
    width: auto;
    margin-top: 1vh;
  }

  .Menu {
    height: 50px; /* Riduzione dell'altezza del menu in verticale */
  }

  .titolo-menu {
    font-family: "Kavoon", sans-serif;
    color: #ffffff;
    font-size: 1.6vh;
  }

  .Menu button {
    width: clamp(30px, 8vw, 50px);
    height: clamp(30px, 8vw, 50px);
  }

  .logo-menu {
    width: clamp(20px, 8vw, 40px);
    height: clamp(20px, 8vw, 40px);
  }
}

/* Smartphone in modalità orizzontale */
@media (max-width: 768px) and (orientation: landscape) {
  .container-home {
    flex-direction: row;
    height: 100vh;
    background-size: cover;
  }

  .dati-container-seleziona {
    font-size: clamp(14px, 4vw, 18px);
  }

  .Menu {
    height: 60px; /* Menu leggermente più alto in orizzontale */
  }

  .Menu button {
    width: clamp(40px, 8vw, 60px);
    height: clamp(40px, 8vw, 60px);
  }

  .logo-menu {
    width: clamp(30px, 8vw, 50px);
    height: clamp(30px, 8vw, 50px);
  }
}
