.addPort-container {
  display: flex;
  flex-direction: row;
  width: auto;
  font-family: Arial, sans-serif;
  gap: 5px;
}

.addPort-container form {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  font-family: Arial, sans-serif;
}

.middle {
  display: flex;
  flex-direction: column;
  height: auto;
  width: auto;
}

.middle-top-show {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  background-color: #0056b3;
  height: auto;
  width: 100%;
  color: white;
  gap: 5px;
  border-bottom-right-radius: 10px;

  margin-top: 0px;
  font-family: Arial;
  font-size: 12px;
  padding: 10px;
}

/* .middle-top-show select {
  font-family: Arial;
  font-size: 11px;
} */

.middle-top {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #0056b3;
  height: auto;
  width: auto;
  color: white;
  gap: 5px;
  border-bottom-right-radius: 10px;

  margin-top: 0px;
  font-family: Arial;
  font-size: 14px;
  padding: 10px;
}

.middle-top input {
  margin-left: 10px;
  width: 70%;
}

.checkbox-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 colonne uguali */
  /* gap: 3px; */
  background-color: #0e54bb;
  color: #ffffff;
  border-bottom-right-radius: 10px;
  padding: 5px;
  width: 100%;
  height: auto;
  row-gap: 6px;
}

.form-style {
  display: flex;
  align-items: center; /* Allinea verticalmente label e checkbox */
  gap: 5px; /* Spaziatura tra checkbox e testo */
}

.middle-top-sx {
  height: 100%;
  width: 100%;
  font-family: Arial;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  border-left: 2px solid white;
  border-top-left-radius: 10px;
  text-align: right;
  gap: 2px;
}

.middle-top-dx {
  height: 100%;
  width: 100%;
  font-family: Arial;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  border-left: 2px solid white;
  padding-left: 5px;
  border-top-left-radius: 10px;
  text-align: right;

  gap: 2px;
}

.middle-bottom {
  height: auto;
  width: auto;
  display: flex;
  flex-direction: column;
  padding: 10px;
  color: white;
  border-bottom-right-radius: 10px;
  background-color: #0056b3;
}

.middle-bottom-sx {
  height: 100%;
  width: 100%;
  font-family: Arial;
  font-size: 14px;
  display: flex;
  flex-direction: column;

  border-left: 2px solid white;
  border-top-left-radius: 10px;
}

.middle-bottom-dx {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  font-family: Arial;
  font-size: 14px;
  border-left: 2px solid white;
  border-top-left-radius: 10px;
}

.input-title {
  font-family: Arial;
  padding: 5px;
  padding-left: 10px;
  padding-top: 5px;
  padding-right: 10px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  text-align: center;
  width: auto;
  background-color: #0056b3;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: inline-block;
  margin-top: 5px;
}

.form-style {
  font-family: Arial;
  font-size: 1.5vh;
}

.bottom {
  color: white;
  text-align: center;
  padding: 10px;
  margin-top: 5px;
}

.middle-bottom-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 100%;
  background-color: #3498db;
}

.bottom button {
  width: auto;
  padding: 5px 20px;
  background-color: #0e54bb;
  color: white;
  border: none;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  cursor: pointer;
  margin-top: 0px;
}

.bottom button:hover {
  font-weight: bold;
  background-color: white;
  color: #0e54bb;
}

.top1 {
  font-family: Arial;
  padding: 5px;
  /* padding-left: 20px; */
  font-size: 16px;
  font-weight: bold;
  color: white;
  text-align: center;
  width: auto;
  background-color: #0056b3;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  /* display: inline-block; */
  margin-top: 0px;
}

.filtri-sx {
  font-family: Arial;
  padding: 5px;
  margin-right: 10px;
  /* padding-left: 20px; */
  font-size: 12px;
  width: 30%;
}

.filtri-dx {
  font-family: Arial;
  padding: 5px;
  margin-right: 10px;
  /* padding-left: 20px; */
  font-size: 12px;
  width: 100%;
}

.dati-generali {
  background-color: #3498db;
  padding-left: 10px;
  margin-top: 5px;
  /* gap: 5px; */
  font-family: Arial;
  font-size: 12px;
}

.servizi {
  background-color: #3498db;
  padding-left: 10px;
  margin-top: 5px;
  /* gap: 5px; */
  font-family: Arial;
  font-size: 12px;
}

.grid-item {
  padding: 5px;
  text-align: left;
}

.checkbox-grid-dati p {
  height: 100%;
  padding: 0px;
  margin-bottom: 0px;
  margin-top: 0px;
  /* padding-left: 10px; */
  /* gap: 5px; */
  font-family: Arial;
  font-size: 12px;
}

.descr-dati {
  font-family: Arial;
  font-size: 14px;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
  padding-left: 10px;
  padding-top: 5px;
}

.input-title-dati {
  font-family: Arial;
  padding: 5px;
  padding-left: 20px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  text-align: center;
  background-color: #3498db;
  border-top-left-radius: 10px;
  display: inline-block;
  margin-top: 5px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 5px;
  border-top-right-radius: 10px;
}

.middle-bottom-dati {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 0px;
  /* padding: 10px; */
  color: white;
  /* border-bottom-right-radius: 10px; */
  background-color: #3498db;
}

.dati {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  justify-content: center;
  font-family: Arial;
  background-color: #3498db;
  width: 100%;
}

.feedback-section {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  justify-content: center;
  font-family: Arial;
  background-color: #3498db;
  width: 100%;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  align-items: center;
  gap: 10px;
}

.feedback-section h3 {
  flex-direction: row;
  margin-top: 20px;
  justify-content: center;
  font-size: 16px;
  text-align: center;

  margin-top: 10px;
  margin-bottom: 0px;
  color: white;
  font-family: Arial;
  background-color: #3498db;
}

.feedback-section p {
  margin-top: 0px;
  margin-bottom: 0px;
}

.stars {
  display: flex;
  justify-content: center;
  font-size: 1.6rem;
  cursor: pointer;
  margin-top: 0px;
  gap: 5px;
}

.weather-section {
  display: flex;
  background-color: #3498db;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 12px;
  margin: 10px 0;
  gap: 10px;
  padding: 5px;

  margin-top: 0px;
}

.weather-section-dati {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3498db;
  flex-direction: row;
  color: #ffffff;
  font-size: 12px;
  /* gap: 5px; */
  /* padding: 10px; */
  margin-top: 0px;
}

.weather-section-dati-titolo {
  justify-content: center;
  font-weight: bold;
}

.weather-section h3 {
  font-size: 14px;
  margin: 10px 0;
}

.weather-section-porto {
  font-size: 14px;
  margin: 10px 0;
}

.weather-section p strong {
  margin-left: 10px;
}

.weather-section svg {
  margin-right: 10px;
  color: #ffffff;
  font-size: 18px;
}

/* Media Queries per orientamento */

/* Smartphone in modalità verticale */
@media (max-width: 768px) and (orientation: portrait) {
  .addPort-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: Arial, sans-serif;
    gap: 5px;
  }

  .filtri-sx {
    margin-right: 0px;
    width: auto;
  }

  .filtri-dx {
    border-top: 0px;
    border-bottom: 0px;
    font-family: Arial;
    /* padding: 5px; */
    margin-right: 10px;
    /* padding-left: 20px; */
    font-size: 12px;
    width: auto;
  }

  .middle-top-show {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: 1vw;
    border-bottom-right-radius: 10px;
    width: auto;
    margin-top: 0px;
    font-family: Arial;
    font-size: 12px;
    padding: 10px;
  }

  .filtri-titolo {
    font-family: Arial;
    font-size: 1.3vh;
  }

  .filtri-select select option {
    font-family: Arial;
    font-size: 1.1vh;
    line-height: 2;
  }

  .filtri-select {
    display: flex; /* Dispone i figli in una riga */
    flex-direction: column;
    justify-content: center;
    max-width: 100%; /* Limita la larghezza del contenitore */
    padding: 0px; /* Padding interno */
    box-sizing: border-box;
  }

  .filtri-select select {
    flex: 1; /* Assegna larghezza uguale ai <select>, adattandosi al contenitore */
    min-width: 0; /* Evita che i <select> superino i limiti */
    max-width: 100%; /* Assicura che non eccedano i limiti del contenitore */
    /* padding: 5px; */
    font-size: 1.5vh; /* Dimensione del font */
  }

  .checkbox-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 4 colonne uguali */
    /* gap: 3px; */
    background-color: #0e54bb;
    color: #ffffff;
    border-bottom-right-radius: 10px;
    width: 100%;
    height: auto;
    row-gap: 1px;
    flex-wrap: wrap;
  }
  .form-style {
    font-family: Arial;
    font-size: 1.3vh;
    flex-wrap: wrap;
  }

  .dati-container-seleziona {
    font-size: 4vw;
    height: auto;
    padding: 1vh;
  }

  .Menu {
    height: 50px; /* Riduzione dell'altezza del menu in verticale */
  }

  .Menu button {
    width: clamp(30px, 8vw, 50px);
    height: clamp(30px, 8vw, 50px);
  }

  .logo-menu {
    width: clamp(20px, 8vw, 40px);
    height: clamp(20px, 8vw, 40px);
  }
}

/* Smartphone in modalità orizzontale */
@media (max-width: 768px) and (orientation: landscape) {
  .dati-container-seleziona {
    font-size: clamp(14px, 4vw, 18px);
  }

  .Menu {
    height: 60px; /* Menu leggermente più alto in orizzontale */
  }

  .Menu button {
    width: clamp(40px, 8vw, 60px);
    height: clamp(40px, 8vw, 60px);
  }

  .logo-menu {
    width: clamp(30px, 8vw, 50px);
    height: clamp(30px, 8vw, 50px);
  }
}
